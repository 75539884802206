import React, { useState } from 'react';
import LogisticsCard from './LogisticsCard';
import { LogisticsData } from './Logistics_Data';
import SectionHeading from '../../Common/SectionHeading';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Element } from 'react-scroll';

const LogisticsService = () => {
  const [sliderIndex, setSliderIndex] = useState(0);

  let responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    960: {
      items: 1,
    },
    1200: {
      items: 1,
    },
  };

  return (
    <React.Fragment>
      <Element
        id="logistics_area"
        name="logistics_area"
      >
        <div
          className="container"
          id="trailers"
        >
          <SectionHeading
            heading="Trailers"
            para="Whether you require a temporary trailer for a short-term project or a long-term leasing arrangement, we've got you covered"
          />
          <div className="row">
            <div className="col-md-12">
              <div className="logistics_wrappers">
                <div className="logistic_tabs_button">
                  <ul>
                    <li>
                      <button
                        className={sliderIndex === 0 ? 'active' : ''}
                        onClick={() => setSliderIndex(0)}
                      >
                        Dry Van
                      </button>
                    </li>
                    <li>
                      <button
                        className={sliderIndex === 1 ? 'active' : ''}
                        onClick={() => setSliderIndex(1)}
                      >
                        Stepdeck
                      </button>
                    </li>
                    <li>
                      <button
                        className={sliderIndex === 2 ? 'active' : ''}
                        onClick={() => setSliderIndex(2)}
                      >
                        Flatbed
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="logistics_area_slider">
                  {sliderIndex === 0 && (
                    <OwlCarousel
                      className="owl-theme"
                      responsive={responsive}
                      autoplay={true}
                      autoplayHoverPause={true}
                      autoplayTimeout={2500}
                      loop={true}
                      margin={10}
                      nav={true}
                      dots={false}
                    >
                      {LogisticsData[0].map((data, index) => (
                        <LogisticsCard
                          img={data.img}
                          heading={data.heading}
                          para={data.para}
                          key={index}
                        />
                      ))}
                    </OwlCarousel>
                  )}

                  {sliderIndex === 1 && (
                    <OwlCarousel
                      className="owl-theme"
                      responsive={responsive}
                      autoplay={true}
                      autoplayHoverPause={true}
                      autoplayTimeout={2500}
                      loop={true}
                      margin={10}
                      nav={true}
                      dots={false}
                    >
                      {LogisticsData[1].map((data, index) => (
                        <LogisticsCard
                          img={data.img}
                          heading={data.heading}
                          para={data.para}
                          key={index}
                        />
                      ))}
                    </OwlCarousel>
                  )}

                  {sliderIndex === 2 && (
                    <OwlCarousel
                      className="owl-theme"
                      responsive={responsive}
                      autoplay={true}
                      autoplayHoverPause={true}
                      autoplayTimeout={2500}
                      loop={true}
                      margin={10}
                      nav={true}
                      dots={false}
                    >
                      {LogisticsData[2].map((data, index) => (
                        <LogisticsCard
                          img={data.img}
                          heading={data.heading}
                          para={data.para}
                          key={index}
                        />
                      ))}
                    </OwlCarousel>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </React.Fragment>
  );
};

export default LogisticsService;
