import React from 'react';
import logo from '../../assets/img/logo-light.svg';

const Footer = () => {
  return (
    <>
      <footer id="footer_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="footer_wedget">
                <img
                  src={logo}
                  alt="logo_img"
                  width={200}
                />
                <p>
                  With flexible rental options, top-notch maintenance, and competitive rates, we
                  make it easy for you to get the job done. Contact us today and make your next move
                  hassle-free!
                </p>
                <div className="footer_social_icon">
                  <a href="#!">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#!">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#!">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a href="#!">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-12 offset-2" />

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="footer_wedget">
                <h4>Contact Info</h4>
                <div className="contact-info-footer">
                  <ul>
                    <li>
                      <i className="far fa-envelope"></i>{' '}
                      <a href="mailto:info@trailer53.com"> info@trailer53.com</a>
                    </li>
                    <li>
                      <i className="fas fa-phone-volume"></i>{' '}
                      <a href="tel:+1 (954) 666-5152"> +1 (954) 666-5152</a>
                    </li>
                    <li>
                      <i className="far fa-clock"></i>Mon - Sat: 9:00 - 18:00
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
