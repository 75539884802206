import React from 'react';

const ContactInfo = () => {
  return (
    <>
      <div className="contact_info_wrapper mb-5">
        <div className="mb-3">
          <p>
            Ready to lease a trailer and elevate your transportation capabilities? Contact Trailer53
            today to discuss your requirements, request a quote, or schedule a consultation. Our
            friendly team is standing by to assist you and provide a leasing solution tailored to
            your business needs. Trust us for reliable trailers and exceptional service that goes
            above and beyond your expectations.
          </p>
        </div>
        <div className="contact_item">
          <h5>Call us on</h5>
          <p>Customer Care: +1 (954) 666-5152</p>
        </div>
        <div className="contact_item">
          <h5>Mail Us at</h5>
          <p>info@trailer53.com</p>
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
