import img1 from '../../../assets/img/logistic/53-dry-van-1110x500.jpg';
import img2 from '../../../assets/img/logistic/53-stepdeck-1110x500.jpg';
import img3 from '../../../assets/img/logistic/53-flatbed-1110x500.jpg';

export const LogisticsData = [
  [
    {
      img: img1,
      heading: '1.Dry Van',
      para: 'We have a diverse selection of modern dry vans ready for you. Our inventory includes various types such as swing and overhead door models, all equipped with trailer tracking at no extra charge.',
    },
  ],
  [
    {
      img: img2,
      heading: '2.Stepdeck',
      para: 'We provide an extensive selection of flatbed trailers, each equipped with sliding winches, detachable bulkheads, and long-lasting brake linings. You can choose between closed or spread-axle tandems.',
    },
  ],
  [
    {
      img: img3,
      heading: '3.Flatbed',
      para: 'We offer drop-deck trailers that can be effectively used for heavy and extra-tall cargo since they can have extra axles.  Drop-deck can also be custom built with a variety of options to meet your operational requirements.',
    },
  ],
];
