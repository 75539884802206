import React from 'react';
import HomeBanner from '../component/Home_One/Banner';
import LogisticsService from '../component/Home_One/Logistics_Services';
import OurAdvantage from '../component/Home_One/Our_Advantages';
import Subscribe from '../component/Common/Subscribe';

const index = () => {
  return (
    <>
      <HomeBanner />
      <LogisticsService />
      <OurAdvantage />
      <Subscribe />
    </>
  );
};

export default index;
