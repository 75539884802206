export const FaqsData = [
  {
    heading: 'Consultation',
    para: `Contact our team to discuss your trailer needs, including the type of trailer, leasing duration, and any customization requirements you may have. We'll provide you with expert guidance and recommend the best options for your specific needs.
`,
  },
  {
    heading: 'Selection and Customization',
    para: `Choose from our extensive inventory of trailers and discuss any customization options you require. Whether you need your company logo displayed or specific modifications to the trailer's interior, we'll work closely with you to bring your vision to life.
`,
  },
  {
    heading: 'Lease Agreement',
    para: `Once you've selected your trailer and finalized the details, we'll prepare the lease agreement outlining the terms and conditions. Our leasing process is straightforward, transparent, and designed to accommodate your unique business needs.
`,
  },
  {
    heading: 'End of Lease',
    para: `At the end of the lease term, simply return the trailer to us, and we'll handle the rest. If you require an extension or want to explore other leasing options, our team will assist you in making the necessary arrangements.
`,
  },
];
