import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import "./assets/css/style.css"
import "./assets/css/meanmenu.min.css"
import "./assets/css/responsive.css"
import "./assets/css/animate.min.css"
import "./assets/css/fontawesome.all.min.css"
import "./assets/css/color.css"

const rootElement = document.getElementById('root');

ReactDOM.createRoot(rootElement).render(<React.StrictMode>
    <App/>
</React.StrictMode>);
