import React, { useState } from 'react';
import FormInput from '../Common/FormInput';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_USER_ID,
      )
      .then(
        () => {
          toast.success('Email sent successfully');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

    setFormData({
      to_name: 'info@trailer53.com',
      from_name: '',
      from_email: '',
      subject: '',
      message: '',
    });
  };

  return (
    <form onSubmit={sendEmail}>
      <div className="row">
        <div className="col-lg-12">
          <FormInput
            tag="input"
            type="text"
            name="from_name"
            classes="form-control"
            placeholder="Name"
            value={formData.from_name}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12">
          <FormInput
            tag="input"
            type="email"
            name="from_email"
            classes="form-control"
            placeholder="Email"
            value={formData.from_email}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12">
          <FormInput
            tag="input"
            type="text"
            name="subject"
            classes="form-control"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12">
          <FormInput
            tag="textarea"
            name="message"
            classes="form-control"
            placeholder="Type Your Messages..."
            value={formData.message}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12">
          <div className="contact_form_submit">
            <FormInput
              tag="button"
              type="submit"
              val="Send"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
