import React from 'react';
import CommonBanner from '../component/Common/Banner';
import ContactDetails from '../component/Contact';

const Contact = () => {
  return (
    <>
      <CommonBanner
        heading="Contact Us"
        page="Contact Us"
      />
      <ContactDetails />
    </>
  );
};

export default Contact;
