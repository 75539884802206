import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './component/Common/Navbar';
import Footer from './component/Common/Footer';
import CopyRight from './component/Common/CopyRight';
import 'react-toastify/dist/ReactToastify.css';

import HomeOne from './page/index';
import About from './page/About';
import Error from './page/Error';
import Contact from './page/Contact';
import RequestQuote from './page/RequestQuote';

import ScrollToTop from './component/ScrollToTop';
import { ToastContainer } from 'react-toastify';

const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={<HomeOne />}
          />
          <Route
            path="/about"
            element={<About />}
          />
          <Route
            path="/request_quote"
            element={<RequestQuote />}
          />
          <Route
            path="/contact"
            element={<Contact />}
          />
          <Route
            path="*"
            element={<Error />}
          />
        </Routes>
        <Footer />
        <CopyRight />
        <ToastContainer />
      </Router>
    </>
  );
};

export default App;
