import React from 'react';
// CopyRight Area
const CopyRight = () => {
  return (
    <>
      <div id="copy_right">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="copy-right-area">
                <p>
                  Copyright @2024 <span>Trailer53.</span> All Rights Reserved{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyRight;
