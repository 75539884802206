import React from 'react';
import CommonBanner from '../component/Common/Banner';
import HomeTwoAbout from '../component/Home_Two/About';
import OurPartner from '../component/Common/OurPartner';
import { FaqsData } from '../component/Faqs/FaqsData';
import SectionHeading from '../component/Common/SectionHeading';

const About = () => {
  return (
    <>
      <CommonBanner
        heading="About"
        page="About"
      />
      <HomeTwoAbout />
      <section id="home_two_service">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <SectionHeading
                heading="How It Works?"
                para="Leasing a trailer through Trailer53 is a straightforward and streamlined process"
              />
              <div className="tab-pane fade show active">
                <div className="faqs-items">
                  <div
                    id="accordion"
                    className="accordion-wrapper"
                  >
                    {FaqsData.map((data, index) => (
                      <div
                        id={'accordion' + index}
                        key={index}
                      >
                        <div className="card  box-shadow">
                          <div
                            className="card-header"
                            id={'heading' + index}
                          >
                            <h5 className="mb-0">
                              <a
                                href="#!"
                                className="collapsed "
                                role="button"
                                data-toggle="collapse"
                                data-target={'#collapse' + index}
                                aria-expanded="false"
                                aria-controls={'collapse' + index}
                              >
                                {data.heading}
                              </a>
                            </h5>
                          </div>
                          <div
                            id={'collapse' + index}
                            className={index === 0 ? 'collapse show active' : 'collapse'}
                            aria-labelledby={'heading' + index}
                            data-parent={'#accordion'}
                          >
                            <div className="card-body active">
                              <p>{data.para}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OurPartner />
    </>
  );
};

export default About;
