import React from 'react';

const FormInput = (props) => {
  let options = props.options || [];
  return (
    <>
      <div className="form-group">
        {props.label && <label htmlFor="namedInput">{props.label}</label>}
        {props.tag === 'input' && (
          <input
            type={props.type}
            name={props.name}
            placeholder={props.placeholder}
            className={props.classes}
            value={props.value}
            onChange={props.onChange}
          />
        )}
        {props.tag === 'textarea' && (
          <textarea
            name={props.name}
            cols="30"
            rows="7"
            placeholder={props.placeholder}
            className={props.classes}
            value={props.value}
            onChange={props.onChange}
            required
          />
        )}
        {props.tag === 'password' && (
          <input
            type={props.type}
            name={props.name}
            placeholder={props.placeholder}
            className={props.classes}
            value={props.value}
            onChange={props.onChange}
          />
        )}
        {props.tag === 'number' && (
          <input
            type={props.type}
            name={props.name}
            placeholder={props.placeholder}
            className={props.classes}
            value={props.value}
            onChange={props.onChange}
          />
        )}
        {props.tag === 'button' && <button className={`btn btn-theme`}>{props.val}</button>}
        {props.tag === 'select' && (
          <select
            className="form-control first_null"
            id="city"
            name={props.name}
            value={props.value}
            onChange={props.onChange}
          >
            {options.map((data, index) => (
              <option
                key={index}
                value={data.value}
              >
                {data.text}
              </option>
            ))}
          </select>
        )}
      </div>
    </>
  );
};

export default FormInput;
