export const OurAdventagesData = [
  [
    {
      heading: '-Rent/ Lease',
      para: 'Our trailer service offers a wide range of trailers to meet your specific needs with flexible leasing and renting options',
    },
    {
      heading: '-Roadside Repair Service',
      para: '24/7 ROADSIDE ASSISTANCE Support for mechanical, tire, and towing emergencies.',
    },
    {
      heading: '-Find LOT trailers',
      para: 'We provide short-term rental LOT trailers from any US state with a specified drop address.',
    },
  ],
  [
    {
      heading: '- Assistance with trailer moves',
      para: 'Our assistance will be needed if you are having difficulties relocating your trailers from one state to another.',
    },
    {
      heading: '-GPS ordering and installation',
      para: 'Obtain comprehensive understanding of when, where, and why your trailers are used to empower your optimized business.',
    },
    {
      heading: '-Stickers for your trailers',
      para: 'These customized trailer decals are made to resist any weather due to our superior sticky and high-quality materials, guaranteeing that your message stays clear and colorful',
    },
  ],
];
