import React from 'react';
// Import Img
import img1 from '../../../assets/img/common/53-about-us.jpg';

const HomeTwoAbout = () => {
  return (
    <>
      <section id="service_about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="about_service_img">
                <img
                  src={img1}
                  alt="img-about"
                />
              </div>
            </div>
            <div className="col-lg-6  col-md-12 col-sm-12 col-12">
              <div className="about_service_text">
                <div className="heading-left-border">
                  <h2>Move ahead with us</h2>
                </div>
                <p>
                  Welcome to Trailer53, your premier destination for leasing high-quality trailers
                  in the USA. With our extensive fleet and exceptional service, we provide
                  businesses of all sizes with reliable and cost-effective trailer solutions to meet
                  your transportation needs. Whether you require a temporary trailer for a
                  short-term project or a long-term leasing arrangement, we've got you covered.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTwoAbout;
