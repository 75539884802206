import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link, useLocation } from 'react-router-dom';

const MenuItems = (props) => {
  const location = useLocation();
  const isNotHome = location.pathname !== '/';

  const linkStyle = isNotHome
    ? {
        cursor: 'pointer',
        color: 'white',
      }
    : {
        cursor: 'pointer',
      };

  return (
    <li className="nav-item">
      {props.item.has_scroll ? (
        <ScrollLink
          to={props.item.href}
          style={linkStyle}
          spy={true}
          smooth={true}
          duration={500}
        >
          {props.item.name}
        </ScrollLink>
      ) : (
        <Link
          to={props.item.href}
          className="nav-link"
          style={linkStyle}
        >
          {props.item.name}
        </Link>
      )}
    </li>
  );
};

export default MenuItems;
