import React, { useState } from 'react';
import FormInput from '../Common/FormInput';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

const options1 = [
  {
    text: 'Please select one of our services',
    value: '',
  },
  {
    text: '-Rent/ Lease',
    value: '-Rent/ Lease',
  },
  {
    text: '-Roadside Repair Service',
    value: '-Roadside Repair Service',
  },
  {
    text: '-Find LOT trailers',
    value: '-Find LOT trailers',
  },
  {
    text: '- Assistance with trailer moves',
    value: '- Assistance with trailer moves',
  },
  {
    text: '-GPS ordering and installation',
    value: '-GPS ordering and installation',
  },
  {
    text: '-Stickers for your trailers',
    value: '-Stickers for your trailers',
  },
];

const options2 = [
  {
    text: 'Please select one of our trailers',
    value: '',
  },
  {
    text: '-Dry Vans',
    value: '-Dry Vans',
  },
  {
    text: '-Stepdeck',
    value: '-Stepdeck',
  },
  {
    text: '-Flatbed',
    value: '-Flatbed',
  },
];

const RequestQuoteForm = () => {
  const [needsTrailer, setNeedsTrailer] = useState(false);

  const [formData, setFormData] = useState({
    service: '',
    trailer: '',
    fname: '',
    lname: '',
    from_email: '',
    number: '',
    message: '',
  });

  const handleCheckboxChange = (e) => {
    setNeedsTrailer(e.target.checked);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const emailParams = {
      service: formData.service,
      trailer: needsTrailer ? formData.trailer : 'N/A',
      fname: formData.fname,
      lname: formData.lname,
      from_email: formData.from_email,
      number: formData.number,
      message: formData.message,
      from_name: `${formData.fname} ${formData.lname}`,
    };

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        emailParams,
        process.env.REACT_APP_USER_ID,
      )
      .then(
        () => {
          toast.success('Your message has been sent successfully!');
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert('Failed to send message, please try again later.');
        },
      );

    setFormData({
      service: '',
      trailer: '',
      fname: '',
      lname: '',
      from_email: '',
      number: '',
      message: '',
    });
    setNeedsTrailer(false);
  };

  return (
    <>
      <section id="request_quote_form_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12 col-12">
              <form
                id="request_form"
                onSubmit={sendEmail}
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="heading_quote">
                      <h3>Get a quote</h3>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="needsTrailer"
                        checked={needsTrailer}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="needsTrailer"
                      >
                        Do you need a trailer?
                      </label>
                    </div>
                  </div>
                  {needsTrailer && (
                    <div className="col-lg-6">
                      <FormInput
                        tag="select"
                        classes="form-control"
                        options={options2}
                        label="Trailer"
                        name="trailer"
                        value={formData.trailer}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  <div className="col-lg-6">
                    <FormInput
                      tag="select"
                      classes="form-control"
                      options={options1}
                      label="Service"
                      name="service"
                      value={formData.service}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-12">
                    <div className="heading_quote arae_top">
                      <h3>Your Personal Details</h3>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <FormInput
                      tag="input"
                      type="text"
                      name="fname"
                      classes="form-control"
                      placeholder="First Name"
                      label="First Name"
                      value={formData.fname}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormInput
                      tag="input"
                      type="text"
                      name="lname"
                      classes="form-control"
                      placeholder="Last Name"
                      label="Last Name"
                      value={formData.lname}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormInput
                      tag="input"
                      type="text"
                      name="from_email"
                      classes="form-control"
                      placeholder="Email"
                      label="Your Email"
                      value={formData.from_email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormInput
                      tag="input"
                      type="number"
                      name="number"
                      classes="form-control"
                      placeholder="Phone Number"
                      label="Phone Number"
                      value={formData.number}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-12">
                    <FormInput
                      tag="textarea"
                      name="message"
                      classes="form-control"
                      placeholder="Your Messages"
                      label="Your Messages"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-12">
                    <div className="quote_submit_button">
                      <button
                        type="submit"
                        className="btn btn-theme"
                      >
                        Send Messages
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RequestQuoteForm;
