export const MenuData = [
  {
    name: 'Home',
    href: '/',
    has_scroll: false,
  },
  {
    name: 'About',
    href: '/about',
    has_scroll: false,
  },
  {
    name: 'Trailers',
    href: 'logistics_area',
    has_scroll: true,
  },
  {
    name: 'Services',
    href: 'our_advantages_area',
    has_scroll: true,
  },
  {
    name: 'Contact',
    href: '/contact',
    has_scroll: false,
  },
];
