import React from 'react';
import coverVideo from '../../assets/videos/trucks-cover-2.webm';
import styled from 'styled-components';

const VideoBox = styled.div`
    height: 100vh;
    width: 100%;
    position: absolute;

    video {
        width: 100%;
        position: absolute;

        @media (max-width: 768px) {
            width: unset;
            height: 100%;
        }
    }
`;

function CoverVideo() {
  return (
    <VideoBox>
      <video
        controls={false}
        autoPlay={true}
        muted={true}
        loop={true}
      >
        <source
          src={`${coverVideo}#t=0.1`}
          type="video/mp4"
        />
      </video>
    </VideoBox>
  );
}

export default CoverVideo;
